import PropTypes from "prop-types";
import React from "react";
import ReactTable from "react-table";
import columns from "./columns";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "../../Button";

const InterventionsTable = ({ interventions, closeDialog }) => {
  const data = interventions.map((intervention) => {
    // reshape for table
    return {
      ...intervention?.resolution,
      id: intervention.id,
    };
  });

  return (
    <Dialog
      open={true}
      contentlabel="Events History"
      ariaHideApp={false}
      fullWidth={true}
      maxHeight="80%"
      maxWidth={"md"}
    >
      <DialogTitle>
        <Typography color="secondary" variant="h6">
          Interventions
        </Typography>
      </DialogTitle>
      <DialogContent>
        <ReactTable
          data={data}
          columns={columns}
          className="-striped -highlight"
          defaultPageSize={10}
          filterable
          defaultSorted={[
            {
              id: "id",
              desc: true,
            },
          ]}
        />
      </DialogContent>
      <Button
        color="primary"
        varian="raised"
        onClick={async () => {
          await closeDialog();
        }}
      >
        Close
      </Button>
    </Dialog>
  );
};

InterventionsTable.propTypes = {
  interventions: PropTypes.array,
};

export default InterventionsTable;
